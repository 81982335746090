<template>
  <main>
    <div style="display: flex; justify-content: center; align-items: center; flex-direction: column; ">
      <div style="margin-bottom: 4rem;">
        <input multiple type="file" />
      </div>
      <div>
        <button @click="uploadFiles">Upload files</button>
      </div>
    </div>
  </main>
</template>

<script>
import { Component, Prop, Vue } from 'vue-property-decorator';
import axios, { AxiosResponse } from "axios";
import S3 from 'aws-s3';

axios.defaults.headers.apikey = process.env.VUE_APP_API_KEY;

@Component
export default class UploadView extends Vue {
  
}
</script>
