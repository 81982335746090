import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import UploadView from '../views/UploadView.vue'
import EditView from '../views/EditView.vue'
import AddView from '../views/AddView.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'fabrics',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/FabricsView.vue')
  },
  {
    path: '/edit',
    name: 'edit',
    props: true,
    component: EditView
  },
  {
    path: '/new',
    name: 'new',
    props: true,
    component: AddView
  },
  {
    path: '/upload',
    name: 'upload',
    props: true,
    component: UploadView
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
