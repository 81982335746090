
import { Component, Prop, Vue } from 'vue-property-decorator';
import UIkit from "uikit";
import axios from "axios";
axios.defaults.headers.apikey = process.env.VUE_APP_API_KEY;

@Component({
  components: {
    // HelloWorld,
  }

})
export default class EditView extends Vue {
  @Prop() private code!: string;
  selectedFabric: any;
  mfr_code: string | undefined;
  id: string | undefined;
  checkedColors: any[] | undefined;
  colorGroups: any;
  patternGroups: any;
  data() {
    return {
      mfr_code: null,
      selectedFabric: null,
      id: null,
      checkedColors: [],
      colorGroups: [
        { name: 'Beige' },
        { name: 'Black' },
        { name: 'Blue' },
        { name: 'Brown' },
        { name: 'Gold' },
        { name: 'Grey' },
        { name: 'Green' },
        { name: 'Ivory' },
        { name: 'Orange' },
        { name: 'Pink' },
        { name: 'Purple' },
        { name: 'Red' },
        { name: 'Turquoise' },
        { name: 'White' },
        { name: 'Yellow' },
      ],
      patternGroups: [
        { name: 'Solids' },
        { name: 'Stripes' },
        { name: 'Textures' },
        { name: 'Geometrics' },
        { name: 'Tweeds' },
        { name: 'Organic' },
      ],
    }
  }
  mounted() {
    if (this.code) {
      this.mfr_code = this.code,
        this.getFabric();
    }
  }

  public async getFabric(): Promise<void> {
    let url = process.env.VUE_APP_API_NODE_URL + "/fabrics/" + this.code;
    axios.get(url).then((result) => {
      this.selectedFabric = result.data;
    })
  }

  public inArray(needle: string, haystack: []) {
    var length = haystack.length;
    for (var i = 0; i < length; i++) {
      if (haystack[i] == needle) return true;
    }
    return false;
  }

  public async updateFabric(): Promise<void> {
    let url = process.env.VUE_APP_API_NODE_URL + "/fabrics/update/" + this.selectedFabric.data._id;

    // Set array values and replace originals
    this.selectedFabric.data.color_group = Array.from(document.querySelectorAll("input[name='color_group']:checked")).map((elem: any) => elem.value)
    this.selectedFabric.data.pattern_group = Array.from(document.querySelectorAll("input[name='pattern_group']:checked")).map((elem: any) => elem.value)
    this.selectedFabric.data.equivalents = Array.from(document.querySelectorAll("input[name='fabric_equiv']")).map((elem: any) => elem.value)
    this.selectedFabric.data.retired_names = Array.from(document.querySelectorAll("input[name='retired_names']")).map((elem: any) => elem.value)

    // Set field handles
    this.selectedFabric.data.mfr_handle = (this.selectedFabric.data.mfr).toLowerCase();
    this.selectedFabric.data.brand_handle = (this.selectedFabric.data.brand).toLowerCase();
    this.selectedFabric.data.brand_collection_handle = (this.selectedFabric.data.brand_collection).toLowerCase();
    
    try {
      axios.put(url, this.selectedFabric.data).then((result) => {
        console.log(result.data)
        if (result.data.status == "success") {
          UIkit.notification({
            message: this.selectedFabric.data.mfr_code + " was updated",
            status: "success"
          });
        }
      })
    } catch (err) {
      UIkit.notification({
        message: "There was a problem saving this fabric - " + err,
        status: "warning"
      });
    }
  }

  public async deleteFabric(): Promise<void> {
    let url = process.env.VUE_APP_API_NODE_URL + "/fabrics/delete/" + this.selectedFabric.data._id;
    try {
      axios.delete(url).then((result) => {
        console.log(result) //TODO: Put in an alert that the swatch was deleted
        if (result.data.status == "deleted") {
          this.$router.push({ name: 'fabrics' })
          UIkit.notification({
            message: this.selectedFabric.data.mfr_code + " was deleted",
            status: "danger"
          });
        }
      })

    } catch (err) {
      console.log(err);
    }

  }
}
