
import { Component, Prop, Vue } from 'vue-property-decorator';
import UIkit from 'uikit';
import fabric from '@/assets/fabric.json'
import axios, { AxiosResponse } from "axios";
axios.defaults.headers.apikey = process.env.VUE_APP_API_KEY;

@Component({
  components: {
    fabric
  },
})
export default class AddView extends Vue {
  checkedColors: any[] | undefined;
  colorGroups: any;
  patternGroups: any;
  fabricData!: typeof fabric;
  images: any;
  $refs: any;
  data() {
    return {
      fabricData: fabric,
      checkedColors: [],
      colorGroups: [
        { name: 'Beige' },
        { name: 'Black' },
        { name: 'Blue' },
        { name: 'Brown' },
        { name: 'Gold' },
        { name: 'Grey' },
        { name: 'Green' },
        { name: 'Ivory' },
        { name: 'Orange' },
        { name: 'Pink' },
        { name: 'Purple' },
        { name: 'Red' },
        { name: 'Turquoise' },
        { name: 'White' },
        { name: 'Yellow' },
      ],
      patternGroups: [
        { name: 'Solids' },
        { name: 'Stripes' },
        { name: 'Textures' },
        { name: 'Geometrics' },
        { name: 'Tweeds' },
        { name: 'Organic' },
      ],
    }
  }
  mounted() {
    console.log(this.fabricData);
  }

  // public uploadFile() {
  //   this.images = this.$refs.file.files[0];
  //   console.log(this.images);
  // }

  // public async submitFile(): Promise<void> {
  //   const url = process.env.VUE_APP_API_NODE_URL + "/upload/wasabi";
  //   const formData = new FormData();
  //   formData.append('file', this.images);
  //   const options = {
  //     headers: {
  //       'Content-Type': 'multipart/form-data',
  //     }
  //   };
  //   const body = formData;
  //   try {
  //     const res: AxiosResponse<any> = await axios.post(
  //       url,
  //       body,
  //       options
  //     );
  //     console.log(res);
  //   } catch(err) {
  //     console.log(err);
  //   }
  // }

  public async addFabric(): Promise<void> {
    let url = process.env.VUE_APP_API_NODE_URL + "/fabrics";

    // Set array values and replace originals
    this.fabricData.color_group = Array.from(document.querySelectorAll("input[name='color_group']:checked")).map((elem: any) => elem.value)
    this.fabricData.pattern_group = Array.from(document.querySelectorAll("input[name='pattern_group']:checked")).map((elem: any) => elem.value)
    this.fabricData.equivalents = Array.from(document.querySelectorAll("input[name='fabric_equiv']")).map((elem: any) => elem.value)
    this.fabricData.retired_names = Array.from(document.querySelectorAll("input[name='retired_names']")).map((elem: any) => elem.value)

    // Set field handles
    this.fabricData.mfr_handle = (this.fabricData.mfr).toLowerCase();
    if (this.fabricData.mfr_handle == "recasens") {
      this.fabricData.brand = "Recacril";
    } else {
      this.fabricData.brand = this.fabricData.mfr_handle;
    }
    this.fabricData.brand_handle = (this.fabricData.brand).toLowerCase();
    this.fabricData.brand_collection_handle = (this.fabricData.brand_collection).toLowerCase();

    const options = {
      headers: {
        "Content-Type": "application/json",
      }
    };
    const body = JSON.stringify(this.fabricData);
    try {
      const res: AxiosResponse<any> = await axios.post(
        url,
        body,
        options
      );
      console.log(res);
      if (res.status == 201) {
        UIkit.notification({
          message: this.fabricData.mfr_code + " was added to the catalog",
          status: "success"
        });
      }

    } catch (err) {
      console.log(err);
    }
  }
}
